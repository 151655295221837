/**
 * @copyright  Copyright © Leviathan Studios, Licensed under MIT
 * @author     Greg Crane <gmc31886@gmail.com>
 */

export const getLatestInstagramPosts = async (count) => {
  const token = process.env.REACT_APP_INSTAGRAM_TOKEN;
  const url = `https://graph.instagram.com/me/media?fields=media_count,caption,media_type,permalink,media_url&&access_token=${token}`;
  let posts = await fetch(url)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error("Error:", error);
    });

  posts = posts.data.length ? posts.data.slice(0, count) : [];

  return posts;
};
