/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect, lazy, Suspense } from "react";
import { checkUserSession} from './store/user/user.action';
import GlobalLoader from './components/loaders/global-loader.component.jsx';

const Home = lazy(() => import('./routes/home/home.component.jsx'));
const LoginLanding = lazy(() => import('./routes/login-landing/long-landing.component.jsx'));
const CoreStructure = lazy(() => import('./routes/core-structure/core-structure.component.jsx'));
const AppointmentsPage = lazy(() => import('./routes/appointments/appointments.component.jsx'));
const ServicesPage = lazy(() => import('./routes/services/services.component.jsx'));
const AboutPage = lazy(() => import('./routes/about/about.component.jsx'));
const ContactPage = lazy(() => import('./routes/contact/contact.component.jsx'));

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(checkUserSession()); }, [dispatch]);

  return (
    <Suspense fallback={<GlobalLoader/>}>
      <Routes>
        <Route path ='/' element={ <CoreStructure /> }>
          <Route index element={ <Home /> }/>
          <Route path='user/*' element={< LoginLanding /> }/>
          <Route path='about/*' element={< AboutPage /> }/>
          <Route path='contact/*' element={ <ContactPage /> }/>
          <Route path='appointments/*' element={ <AppointmentsPage /> }/>
          <Route path='services/*' element={ <ServicesPage /> }/>
        </Route>
      </Routes>
    </Suspense>
   );
};

export default App;
 