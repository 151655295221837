/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { INSTAGRAM_FEED_ACTION_TYPES } from "./instagram.types";

export const INSTAGRAM_FEED_INITIAL_STATE = {
  instagramFeed: [],
  isLoading: false,
  error: null,
};

export const instagramFeedReducer = (state = INSTAGRAM_FEED_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_START:
      return {...state, isLoading: true };
    case INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_SUCCESS:
      return { ...state, instagramFeed: payload, isLoading: false };
    case INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_FAIL:
        return {...state, error: payload, isLoading: false };
    default:
      return state;
  }
}