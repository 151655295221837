/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { INSTAGRAM_FEED_ACTION_TYPES } from "./instagram.types";
import { createAction } from "../../utility/reducer/reducer.utils";

export const fetchInstagramFeedStart = () => createAction(
    INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_START
);

export const fetchInstagramFeedSuccess = (instagramFeedArray) => createAction(
    INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_SUCCESS, instagramFeedArray
);

export const fetchInstagramFeedFail = (error) => createAction(
    INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_FAIL, error
);