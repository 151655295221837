/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Grey Crane <gmc31886@gmail.com>
  */

import { combineReducers } from "redux";
import { userReducer } from "./user/user.reducer";
import { instagramFeedReducer } from "./instagram-feed/instagram.reducer";
import { messagesReducer } from "./message/message.reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    instagramFeed: instagramFeedReducer,
    message: messagesReducer
});