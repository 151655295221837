/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { all, call, put, takeLatest} from 'redux-saga/effects'
import { setMessageSuccess, setMessageFail } from './message.actions';
import MESSAGE_ACTION_TYPES from './message.types';

export function* setGlobalMessage(payload) {
  try {
    yield put(setMessageSuccess(payload));
  } catch (error) {
    yield put(setMessageFail(error));
  }
}

export function* onSetGlobalMessage() {
  yield takeLatest(MESSAGE_ACTION_TYPES.SET_MESSAGE_START, setGlobalMessage);
}

export function* messageSaga() {
  yield all([call(onSetGlobalMessage)]);
}

