/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Grey Crane <gmc31886@gmail.com>
  */

 const MESSAGE_ACTION_TYPES = {
    SET_MESSAGE: 'message/SET_MESSAGE',
    SET_MESSAGE_START: 'message/SET_MESSAGE_START',
    SET_MESSAGE_SUCCESS: 'message/SET_MESSAGE_SUCCESS',
    SET_MESSAGE_FAIL: 'message/SET_MESSAGE_FAIL',
  }
  
 export default MESSAGE_ACTION_TYPES;