/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { createAction } from "../../utility/reducer/reducer.utils";
import MESSAGE_ACTION_TYPES from "./message.types";
 
export const setMessage = (message) => createAction(MESSAGE_ACTION_TYPES.SET_MESSAGE, message);
export const setMessageStart = (message) => createAction(MESSAGE_ACTION_TYPES.SET_MESSAGE_START, message);
export const setMessageSuccess = (message) => createAction(MESSAGE_ACTION_TYPES.SET_MESSAGE_SUCCESS, message);
export const setMessageFail = (error) => createAction(MESSAGE_ACTION_TYPES.SET_MESSAGE_FAIL, error);