/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { takeLatest, all, call, put} from 'redux-saga/effects'
import { getLatestInstagramPosts } from "../../utility/instagram/instagram.utils";
import { fetchInstagramFeedSuccess, fetchInstagramFeedFail } from './instagram.actions';
import { INSTAGRAM_FEED_ACTION_TYPES } from "./instagram.types";

export function* fetchInstagramFeedAsync() {
    try {
        const instagramFeedObject = yield call(getLatestInstagramPosts, 8);
        yield put(fetchInstagramFeedSuccess(instagramFeedObject));
    } catch(error) {
        yield put(fetchInstagramFeedFail(error));
    }
}

export function* onFetchInstagramFeed() {
    yield takeLatest(INSTAGRAM_FEED_ACTION_TYPES.FETCH_INSTAGRAM_FEED_START, fetchInstagramFeedAsync);
}

export function* instagramFeedSaga() {
    yield all([call(onFetchInstagramFeed)]);
}

