/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { all, call } from 'redux-saga/effects';
import { instagramFeedSaga } from './instagram-feed/instagram.saga';
import { userSagas } from './user/user.saga';
import { messageSaga } from './message/message.saga';

export function* rootSaga(){
    yield all([call(instagramFeedSaga), call(userSagas), call(messageSaga)]);
};