/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import MESSAGE_ACTION_TYPES from "./message.types";

export const MESSAGE_INITIAL_STATE = {
     message: '',
     error: null 
};
 
export const messagesReducer = (state = MESSAGE_INITIAL_STATE, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case MESSAGE_ACTION_TYPES.SET_MESSAGE:
                return { ...state, message: payload };
        case MESSAGE_ACTION_TYPES.SET_MESSAGE_SUCCESS:
                return { ...state, message: payload };
        case MESSAGE_ACTION_TYPES.SET_MESSAGE_FAIL:
                return { ...state, error: payload };
        default:
            return state;
   }
}