/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */

import { compose, createStore, applyMiddleware } from 'redux';
import { rootReducer } from './root-reducer';
import { rootSaga } from './root-saga';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage: sessionStorage, // cast var as key name,
  whitelist: [] // can also do blacklist: ['some item']
}
const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
  process.env.NODE_ENV !== 'production' && logger,
  sagaMiddleware
].filter(Boolean); 

const composeEnhancer = (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(persistedReducer, undefined, composedEnhancers);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
