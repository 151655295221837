/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Greg Crane <gmc31886@gmail.com>
  */
 
 import App from './App';
 import React from 'react';
 import ReactDOM from 'react-dom';
 import { BrowserRouter } from 'react-router-dom';
 import { Provider } from 'react-redux';
 import reportWebVitals from './reportWebVitals';
 import { store, persistor } from './store/store';
 import { PersistGate } from 'redux-persist/integration/react';
 import * as serviceWorker from './serviceWorkerRegistration'
 import './index.css';
 
 ReactDOM.render(
   <React.StrictMode>
     <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
     </Provider>
   </React.StrictMode>,
   document.getElementById('root')
 );

 serviceWorker.register();

 // If you want to start measuring performance in your app, pass a function
 // to log results (for example: reportWebVitals(console.log))
 // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 reportWebVitals();
 